<template>
  <div class="stepCard">
    <img id="thumbnailContainer" :src="thumbnail_url" />
    <div class="step">
      <h2 class="stepName">{{stepName}}</h2>
      <span class="stepEnglishName">{{stepEnglishName}}</span>
      <p class="content" v-html="introduce.slice(0,120)"></p>
    </div>
  </div>
</template>

<script>
export default {
  name:'stepCard',
  props:['stepName','stepEnglishName','thumbnail_url','introduce']
}
</script>

<style scoped>
  .stepCard{
    width: 100%;
    min-height: 200px;
    max-height: 300px;
    display: flex;
    margin: auto;
    padding: 10px 20px;
    box-sizing: border-box;
    justify-content: space-around;
    border: 1px solid rgb(242, 242, 242);
    margin-top: 20px;
    align-items: center;
    cursor: pointer;
  }
  @media (max-width:640px) {
    .stepCard{
      width: 100%;
    }
  }
  .step{
    width: 60%;
    padding: 20px;
  }
  .stepName{
    font-size: 20px;
    line-height: 150%;
    display: block;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
  }
  .stepEnglishName{
    color: rgb(127, 127, 127);
    font-size: 0.875rem;
    line-height: 150%;
    display: block;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Helvetica Neue",Helvetica, Arial,Tahoma,"Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  }
  #thumbnailContainer{
    width: 40%;
    height: 100%;
    max-height: 280px;
  }
  .content{
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
</style>