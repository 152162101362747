<template>
   <div id="content">
    <ModuleTitle :chinese="productInfo.detail?.productName" :english="productInfo.detail?.product_English_Name" />
    <div id="MainContent" :style="{flexWrap:productInfo.isMultiImg?'wrap':'unset'}" v-if="productInfo.haveData">
      <template v-if="!productInfo.isMultiImg">
        <div class="ContentImg" v-if="productInfo.detail?.thumbnail">
          <img :src="productInfo.detail?.thumbnail" />
        </div>
        <div
          class="ContentText" 
          :style="{width:!productInfo.detail?.thumbnail ? '100%':''}" 
          v-html="productInfo.detail?.productMainContent"
        >
        </div>
      </template>
      <template v-else>
        <div class="ContentImgGroup">
          <img v-for="(item,index) in productInfo.multiImg" :src="item" :key="index">
        </div>
        <div class="ContentText" style="width:100%" v-html="productInfo.detail?.productMainContent">
        </div>
      </template>
    </div>
    <div class="stepCardContainer">
      <StepCards
        v-for="(item,index) in productInfo.detail?.stepsInfo" :key="index" 
        :stepName="item.itemName_cn"
        :stepEnglishName="item.itemName_en"
        :thumbnail_url="item.itemThumbnail"
        :introduce="item.itemIntroduce"
        @click.native="handleClick(item.id)"
      />
    </div>
    <div v-if="!productInfo.haveData">
      <WithoutContent/>
    </div>
  </div>
</template>

<script>
import ModuleTitle from '../components/common/ModuleTitle'
import StepCards from '../components/product_components/stepsCards'
import WithoutContent from '../components/common/WithoutContent'
export default {
  name:"ProductDetail",
  components:{ModuleTitle,StepCards,WithoutContent},
  data(){
    return{
      productInfo:{
        detail:null,
        haveData:true,
        isMultiImg:false,
        multiImg:null
      }
    }
  },
  methods:{
    getProductInfo(id){
      this.$axios.get(`/read/getProduct?id=${id}`)
      .then(res => {
        const result = res[0]
        if(result.thumbnail && result.thumbnail.indexOf(',')>0){
          this.$set(this.productInfo,'isMultiImg',true)
          this.$set(this.productInfo,'multiImg',result.thumbnail.split(','))
        }else{
          this.$set(this.productInfo,'isMultiImg',false)
        }
        if(!result.productMainContent || result.productMainContent===null){
          this.$set(this.productInfo,'haveData',false)
        }else{
          this.$set(this.productInfo,'haveData',true)
        }
        result.productMainContent = result?.productMainContent?.replaceAll('/n','<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
        result.stepsInfo.forEach(item => {
          item.itemIntroduce = item.itemIntroduce?.replaceAll('/n','<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
        });
        this.$set(this.productInfo,'detail',result)
      })
    },
    handleClick(id){
      this.$router.push(`/solution-product/${id}`)
    }
  },
  created(){
    this.getProductInfo(this.$route.params.id)
  },
  beforeRouteUpdate(to,from,next){
    this.getProductInfo(to.params.id)
    next()
  }
}
</script>

<style scoped>
 #content{
    max-width: 1200px;
    margin: auto;
  }
  .el-breadcrumb__inner a{
    font-weight: normal;
    font-size: 12px;
    color: #333;
  }
  .el-breadcrumb__inner:hover a{
    color: #333;
  }
  .stepCardContainer{
    display: flex;
    flex-wrap: wrap;
  }

  #MainContent{
    display: flex;
    justify-content: space-around;
  }
  .ContentImg{
    width: 43%;
    overflow: hidden;
    padding: 40px;
    box-sizing: border-box;
  }
  @media (max-width:1024px) {
    .ContentImg{
      width: 40%;
      padding: 20px;
      max-height: 378px;
    }
  }

  .ContentImg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .ContentText{
    width: 50%;
    padding: 40px;
    font-size: 1rem;
    text-indent:2em;
    line-height: 1.8rem;
    font-family: 'Microsoft YaHei';
  }
  @media (min-width:1024px) {
    .ContentText{
      font-size: 1.05rem;
    }
  }
  @media (max-width:640px) {
    .stepCardContainer{
      display: block;
    }
    .ContentImg{
      width: 100%;
    }
    .ContentText{
      width: 100% !important;
      box-sizing: border-box;
      font-size: 0.75rem;
      line-height: 1.2rem;
    }
    #MainContent{
      display: block;
    }
  }
  .ContentImgGroup{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .ContentImgGroup img{
    width:40%
  }
</style>